/* Ownersignup.css */

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #ffffff;
}

.centerContent {
  text-align: center;
}

.welcomeText {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 100px;
}

.input {
  width: 100%;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 10px;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  background-color: #4caf50;
  padding: 12px;
  border-radius: 8px;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  margin-bottom: 10px;
}

.signupbutton {
  background-color: #000000;
  padding: 12px;
  border-radius: 8px;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  margin-bottom: 10px;
}
